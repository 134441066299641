.ui.button{
  transition:0s 0.2s;
}

.ui.button:disabled {
  background-color: #E6E6E6 !important
}

.ui.button:active {
  background-color: #ff5a00!important;
  transition:0s;
}

.swap-button:disabled {
  color: black !important;
}

.custom-spinner {
  width: 100px;
  height: 100px;
  border: 15px solid #FFF;
  border-bottom-color: #ff5a00;
  border-radius: 90%;
  display: block;
  animation: rotation 1s linear infinite;
}
@keyframes rotation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}
