/* .ui.button{
  transition:0s 0.2s;
} */

/* .ui.button:disabled {
  background-color: #E6E6E6 !important
} */

.ui.active.button {
  background-color: #ff5a00!important;
  color: white!important;
  transition: 0s
}

.ui.send-button {
  background-color: #ff5a00!important;
  color: white!important;
}

.active.icon:hover {
  color: #ff5a00!important;
}

.ui.star.rating .active.icon {
  color: #ff5a00!important;
}