.ui.button {
  margin-left: 0;
  border-radius: 0px 40px 40px 0px;
}

.active{
  background-color: transparent!important;
  color: #ff5a00!important;
  }

div::-webkit-scrollbar {
  width: 0 !important;
}

.ui.button{
  transition:0s 0.2s;
}

.ui.button:active {
  background-color: #ff5a00!important;
  color: white!important;
}
